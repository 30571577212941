<template>
  <div class="flip-card col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <img :src="imgSrc" :onerror="`this.src='${defaultImg}'`" alt="Image" />
        <div class="flip-card-title">
          <h4 class="cut-1line" :title="offer.offerTitleCurrent">
            {{ isDataExist(offer.offerTitleCurrent) }}
          </h4>
        </div>
      </div>
      <div class="flip-card-back">
        <div class="flip-card-title-top">
          <h6 class="cut-1line">
            {{ isDataExist(offer.fullCode) }}
          </h6>
        </div>
        <div class="flip-card-back-icons">
          <button
            v-b-modal.OfferInfo
            class="btn p-0"
            :title="$t('info')"
            @click="setOfferData()"
          >
            <img src="@/assets/images/info.svg" class="icon-lg" />
          </button>
          <router-link
            v-if="
              checkPrivilege(usersPrivilegeData.usersPrivilegeOffers.update)
            "
            :to="{
              name: 'OfferEdit',
              params: {
                offerToken: offer.offerToken,
              },
            }"
            :title="$t('edit')"
          >
            <img src="@/assets/images/pencil.svg" class="icon-lg" />
          </router-link>
          <button
            v-if="
              checkPrivilege(usersPrivilegeData.usersPrivilegeOffers.archive)
            "
            v-b-modal.OfferDelete
            class="btn p-0"
            :title="$t('delete')"
            @click="setOfferData()"
          >
            <img src="@/assets/images/trash.svg" class="icon-lg" />
          </button>
          <!-- <button
            v-if="offer.offerPreferedTypeToken == prefare.notPrefered"
            v-b-modal.OfferUpdateFavorite
            class="btn p-0"
            :title="$t('addToFavorite')"
            @click="addToFavorite()"
          >
            <img src="@/assets/images/favorite-add.svg" class="icon-lg" />
          </button>
          <button
            v-else-if="offer.offerPreferedTypeToken == prefare.prefered"
            v-b-modal.OfferUpdateFavorite
            class="btn p-0"
            :title="$t('removeFromFavorite')"
            @click="removeFromFavorite()"
          >
            <img src="@/assets/images/favorite-remove.svg" class="icon-lg" />
          </button> -->
          <button
            v-if="
              checkPrivilege(usersPrivilegeData.usersPrivilegeOffers.update)
            "
            v-b-modal.ActionsData
            class="btn p-0"
            :title="$t('actionsData.modelName')"
            @click="setOfferData()"
          >
            <img src="@/assets/images/actions-data.svg" class="icon-lg" />
          </button>
        </div>
        <div class="flip-card-title">
          <h4 class="cut-1line" :title="offer.offerTitleCurrent">
            {{ isDataExist(offer.offerTitleCurrent) }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkPrivilege } from "@/utils/functions";
import { BASE_URL, PREFER_LIST } from "@/utils/constants";
import { isDataExist } from "@/utils/functions";

export default {
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      prefare: PREFER_LIST,
    };
  },
  props: ["offer", "defaultImg"],
  methods: {
    setOfferData() {
      this.$emit("setOfferData", this.offer);
    },
    addToFavorite() {
      this.offer.offerPreferedTypeToken = this.prefare.prefered;
      this.$emit("setOfferData", this.offer);
    },
    removeFromFavorite() {
      this.offer.offerPreferedTypeToken = this.prefare.notPrefered;
      this.$emit("setOfferData", this.offer);
    },
    isDataExist,
    checkPrivilege,
  },
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
    imgSrc: function () {
      return BASE_URL + this.offer.offerImagePath;
    },
  },
};
</script>

<style lang="scss"></style>
