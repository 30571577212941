<template>
  <b-modal id="OfferInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/offers.svg" class="icon-lg" />
        {{ $t("offerData") }}
      </h3>
    </template>
    <div class="">
      <div class="my-card">
        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="offer.fullCode"
            :title="$t('code')"
            :imgName="'code.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="offer.offerPreferedTypeNameCurrent"
            :title="$t('preferedType')"
            :imgName="'favorite.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="offer.brandData.brandNameCurrent"
            :title="$t('brands.name')"
            :imgName="'brand.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="offer.brandServiceData.brandServiceNameCurrent"
            :title="$t('brandServices.name')"
            :imgName="'brand-services.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="offer.offerTitleAr"
            :title="$t('offerTitleAr')"
            :imgName="'offers.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="offer.offerTitleEn"
            :title="$t('offerTitleEn')"
            :imgName="'offers.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12'"
            :value="offer.offerDiscountPercentage"
            :title="$t('brands.discountPercentage')"
            :imgName="'maxDiscountPercentage.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              formateDateTimeLang(offer.offerStartDate, offer.offerStartTime)
            "
            :title="$t('dateTimeStart')"
            :imgName="'dateAndTime.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="formateDateTimeLang(offer.offerEndDate, offer.offerEndTime)"
            :title="$t('dateTimeEnd')"
            :imgName="'dateAndTime.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="offer.offerDescriptionAr"
            :title="$t('offerDescriptionAr')"
            :imgName="'description.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="offer.offerDescriptionEn"
            :title="$t('offerDescriptionEn')"
            :imgName="'description.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12'"
            :value="offer.offerNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { timeToLang, formateDateTimeLang } from "@/utils/functions";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["offer"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    formateDateTimeLang,
  },
};
</script>

<style scoped lang="scss"></style>
