<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="offers.offersData">
      <div class="row">
        <OfferCard
          v-for="(offer, index) in offers.offersData"
          :key="index"
          :defaultImg="offers.offer.defaultImg"
          v-on:setOfferData="offers.offer.fillData($event)"
          :offer="offer"
        />
      </div>

      <ActionsData :actionsData="offers.offer" />

      <OfferInfo :offer="offers.offer" />
      <OfferDelete :offer="offers.offer" v-on:refresh="getAllOffers()" />
      <!-- <OfferUpdateFavorite
        :offer="offers.offer"
        v-on:refresh="getAllOffers()"
      /> -->

      <Pagination
        v-if="!isLoading"
        :paginationData="offers.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <OfferFloatBtns
      :theFilterData="offers.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import ActionsData from "@/components/general/ActionsData.vue";
import OfferCard from "@/components/offers/OfferCard.vue";
import OfferInfo from "@/components/offers/OfferInfo.vue";
import OfferDelete from "@/components/offers/OfferDelete.vue";
// import OfferUpdateFavorite from "@/components/offers/OfferUpdateFavorite.vue";
import OfferFloatBtns from "@/components/offers/OfferFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Offers from "@/models/offers/Offers";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    OfferCard,
    OfferInfo,
    OfferDelete,
    // OfferUpdateFavorite,
    OfferFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      offers: new Offers(),
    };
  },
  methods: {
    changePage(page) {
      this.offers.pagination.selfPage = page;
      this.getAllOffers();
    },
    search(data) {
      this.offers.filterData.fillData(data);
      this.offers.offersData = [];
      this.getAllOffers();
    },
    async getAllOffers() {
      this.isLoading = true;
      try {
        const response = await this.offers.offer.getAllOffers(
          this.language,
          this.userAuthorizeToken,
          this.offers.pagination,
          this.offers.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.offers.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.offers.offersData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.offers.offersData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.offers.offersData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.offers.offersData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllOffers();
  },
};
</script>
