import PaginationData from "@/models/general/PaginationData";
import Offer from "./Offer";
import OffersFilter from "./OffersFilter";

export default class Offers {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.offersData = [];
    this.offer = new Offer();
    this.filterData = new OffersFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.offersData = data.offersData;
  }
}
